import { RegistroDeCustos } from "@/models/RegistroDeCustos";
import { Inject } from "inversify-props"
import { RegistroDeCustosServiceAdapter } from "./adapter";

export default class CreateRegistroDeCustosUseCase {
	@Inject('RegistroDeCustosServiceAdapter')
	private registroDeCustosServiceAdapter!: RegistroDeCustosServiceAdapter

	createPrecoDeCusto = async (registroDeCustos?: RegistroDeCustos): Promise<void> =>
		await this.registroDeCustosServiceAdapter.createPrecoDeCusto(registroDeCustos)

	patchPrecoDeCusto = async (registroDeCustos?: RegistroDeCustos): Promise<void> =>
		await this.registroDeCustosServiceAdapter.patchPrecoDeCusto(registroDeCustos)
}