var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.buscar.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"luid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('SeletorDeLojas',{attrs:{"label":"Selecione a Loja","retornaObjetoLoja":false,"prepend-icon":"mdi-store","clearable":""},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Pesquisa por sku ou ean","single-line":"","hide-details":""},model:{value:(_vm.filtro.skuOuEan),callback:function ($$v) {_vm.$set(_vm.filtro, "skuOuEan", $$v)},expression:"filtro.skuOuEan"}})],1),_c('v-col',{attrs:{"cols":"7","sm":""}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","width":"110","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Buscar ")],1)],1)],1)],1)],1)],1),_c('v-card',[_c('DataTableDeCrud',{attrs:{"tituloParaCriar":"Criar Novo Custo","hideDetail":"","hideDelete":"","headers":_vm.headers,"loading":_vm.carregando,"items":_vm.registroFormatado,"options":_vm.paginacao,"server-items-length":_vm.totalRegistros,"footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] }},on:{"update:options":function($event){_vm.paginacao=$event},"create":function () { return _vm.abrirDialogoDeCriarCusto(); },"update":function (indice) { return _vm.abrirDialogoDeEditarCusto(indice); }},scopedSlots:_vm._u([{key:"item.chaveDanfe",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({staticClass:"chave-danfe"},on),[_vm._v(" "+_vm._s(item.chaveDanfe)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.chaveDanfe))])])]}}],null,true)})],1),_c('DialogoDeEdicaoDeCusto',{ref:"DialogoDeEdicaoDeCusto",attrs:{"custo":_vm.custo},on:{"atualizarLista":_vm.atualizarLista}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }